import LR from "../../components/LeftRight.module.css";

import { groupBy } from "remeda";
import { LeftRight } from "../../components/LeftRight.tsx";
import {
    WSection,
    WSectionHeader,
    WSectionItems,
    WSelect,
    type SectionWithItems,
    type WSelectStyleProps,
    type WSelectValueProps,
} from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import type { ResourceType } from "../api/resourceTypes.tsx";
import { CountryFlag, CountryFlagPadding } from "./CountryFlag.tsx";
import { LocationName } from "./LocationDetail.tsx";
import type { LocationWithEnabled } from "./location.ts";
import { getIso3CountryInfo } from "./locationCountry.ts";
import { useLocationsForType, type LocationSlug } from "./query.ts";

export interface LocationSelectProps extends WSelectStyleProps, WSelectValueProps<LocationWithEnabled, LocationSlug> {
    items: LocationWithEnabled[];
}

export function LocationSelectCustom({ items, ...props }: LocationSelectProps) {
    const groupedItems = groupBy(items, (l) => l.country_code);

    const hasManyCountries = Object.keys(groupedItems).length > 1;

    const countrySections = Object.entries(groupedItems).map(
        ([code, locations]): SectionWithItems<LocationWithEnabled> => {
            const country = getIso3CountryInfo(code);
            return {
                component: (
                    <WSection>
                        <WSectionHeader className={LR.item}>
                            <LeftRight icon={<CountryFlag code={code} />} title={country?.name} />
                        </WSectionHeader>

                        <WSectionItems />
                    </WSection>
                ),
                key: code,
                items: locations,
            };
        },
    );

    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select location"
            sections={countrySections}
            getKey={(item) => item.slug}
            getTextValue={(item) => item.display_name}
            searchItems
            isItemDisabled={(item) => !item.isEnabled}
            renderSelectedValue={(location) => (
                <LeftRight
                    icon={<CountryFlag code={location.country_code} />}
                    title={<LocationName location={location} showCountry={hasManyCountries} />}
                />
            )}
            {...props}
        >
            {(location) => <LeftRight icon={<CountryFlagPadding />} title={<LocationName location={location} />} />}
        </WSelect>
    );
}

export interface LocationSelectLoaderProps extends Omit<LocationSelectProps, "items"> {
    resourceType: ResourceType | undefined;
}

function LocationSelectLoader({ resourceType, ...props }: LocationSelectLoaderProps) {
    const locations = useLocationsForType(resourceType);
    return <LocationSelectCustom items={locations.locations} defaultValueKey={locations.defaultLocation} {...props} />;
}

export function LocationSelect(props: LocationSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <LocationSelectCustom {...props} {...renderProps} />}>
            <LocationSelectLoader {...props} />
        </WSelectWrapper>
    );
}
