import { type ReactNode } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import type { IpAddressWithType } from "../../modules/network/ipAddress/apiOperations.ts";
import RA from "../forms/Radio.module.css";
import R from "../forms/Radiogroup.module.css";

export function DeleteResourceModalContent({
    value,
    children,
    ipAddress,
    onChange,
}: {
    value: string | undefined;
    children: ReactNode;
    ipAddress?: IpAddressWithType;
    onChange: (value: string) => void;
}) {
    return (
        <>
            {children}

            {!!ipAddress && (
                <>
                    <p className="pt-3 pb-3">
                        You have a floating IP <b>{ipAddress?.address}</b> assigned to this resource.
                    </p>

                    <p className="font-size-subtitle pb-2">What would you like to do?</p>
                    <RadioGroup
                        className={R.RadioGroup}
                        aria-label="Keep or delete floating IP"
                        value={value}
                        orientation="horizontal"
                        onChange={(value) => onChange(value)}
                    >
                        <Radio className={RA.Radio} value="no">
                            Keep floating IP
                        </Radio>
                        <Radio className={RA.Radio} value="yes">
                            Delete floating IP
                        </Radio>
                    </RadioGroup>
                </>
            )}
        </>
    );
}
